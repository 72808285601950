import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const WhiteSquareIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<defs>
			<filter id="shadow" x="-20%" y="-20%" width="200%" height="200%">
				<feDropShadow dx="0" dy="1" stdDeviation="2" floodColor="hsla(0, 0%, 0%, 0.12)" />
			</filter>
		</defs>
		<rect x={'4'} y={'4'} width={'18'} height={'18'} rx="2" ry="2" fill="white" filter="url(#shadow)" />
	</SvgIcon>
);
