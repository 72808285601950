import { ColorVariables } from '../models/app.model';

// brandColor and primaryColor are created manually and stored in db
type HSLAPropertyList = 'hue' | 'saturation' | 'lightness' | 'alpha';

const EXTRACT_HSLA_VALUES_REGEX = /[hsla() ]/g;
const COLOR_VALUE_MARKER = '_x_';
const HSLA_BASE_STRING = `hsla(${COLOR_VALUE_MARKER})`;

const getSetOfHSLAValues = (color: string): string[] => color.replace(EXTRACT_HSLA_VALUES_REGEX, '').split(',');

const createHSLAValue = (values: string[]): string => {
	const valuesString = values.join(', ');
	return HSLA_BASE_STRING.replace(COLOR_VALUE_MARKER, valuesString);
};

export const gedHSLAIndexByProperty = (property: HSLAPropertyList): number => {
	switch (property) {
		case 'hue':
			return 0;
		case 'saturation':
			return 1;
		case 'lightness':
			return 2;
		case 'alpha':
			return 3;
	}
};

const updateHSLAValue = (property: HSLAPropertyList) => (color: string, value: string) => {
	const updateValueIndex = gedHSLAIndexByProperty(property);
	const colorValues = getSetOfHSLAValues(color);
	colorValues[updateValueIndex] = value;

	return createHSLAValue(colorValues);
};

const setSaturation = updateHSLAValue('saturation');
const setLightness = updateHSLAValue('lightness');
export const setAlpha = updateHSLAValue('alpha');

/**
 * Receive primary and brand colors, generates colors for app branding
 * format required: hsla(h, s%, l%, a)
 * Just for testing
 * @param primaryColor
 */

export const generatedColorsKeys = [
	'colorPrimarySoft',
	'colorHead',
	'colorText',
	'colorMediumShadow',
	'colorLightShadow',
	'colorDarkShadow',
	'colorBorder',
	'colorCaption',
	'colorNote',
	'colorInactive',
];

export const staticColorsKeys = [
	'colorPrimary',
	'brandColor',
	'colorHeadOnDark',
	'colorTextOnDark',
	'colorBgBody',
	'colorBgOverlay',
	'colorBgSurface',
];

export const systemColorsKeys = [
	// system colors
	// Default
	'colorSysTextDefault',
	'colorSysDefault',
	'colorSysBgDefault',
	// Alert
	'colorSysTextAlert',
	'colorSysAlert',
	'colorSysBgAlert',
	// Warning
	'colorSysTextWarning',
	'colorSysWarning',
	'colorSysBgWarning',
	// Success
	'colorSysTextSuccess',
	'colorSysSuccess',
	'colorSysBgSuccess',
	// Info
	'colorSysTextInfo',
	'colorSysInfo',
	'colorSysBgInfo',
	// Off
	'colorSysTextOff',
	'colorSysOff',
	'colorSysBgOff',
];

export const createColorVariables = (
	colorPrimary: string,
	brandColor: string,
	customColorVariables?: Partial<ColorVariables>,
): ColorVariables => {
	// Generated colors
	const colorPrimarySoft = setLightness(colorPrimary, '96%');
	const colorHead = setLightness(setSaturation(colorPrimary, '100%'), '10%');
	const colorText = setAlpha(colorHead, '0.8');
	const colorMediumShadow = setAlpha(colorHead, '0.08');
	const colorLightShadow = setAlpha(colorHead, '0.02');
	const colorDarkShadow = setAlpha(colorHead, '0.14');

	const colorBorder = setLightness(setSaturation(colorPrimary, '7%'), '78%');
	const colorCaption = setLightness(colorBorder, '43%');
	const colorNote = setLightness(colorBorder, '62%');
	const colorInactive = setLightness(colorBorder, '94%');

	// Fixed colors
	const colorHeadOnDark = 'hsla(0, 0%, 100%, 1)';
	const colorTextOnDark = setAlpha(colorHeadOnDark, '0.8');
	const colorLightShadowNoOpacity = '#F0F0F1';

	const colorBgBody = 'hsla(0, 0%, 100%, 1)';
	const colorBgOverlay = setAlpha(colorBgBody, '0.96');
	const colorBgSurface = 'hsla(0, 0%, 100%, 1)';

	// Fixed and not customizable system colors

	// Default
	const colorSysTextDefault = 'hsla(0, 0%, 3%, 1)';
	const colorSysDefault = 'hsla(0, 0%, 53%, 1)';
	const colorSysBgDefault = 'hsla(0, 0%, 98%, 1)';

	// Alert
	const colorSysTextAlert = 'hsla(0, 74%, 42%, 1)';
	const colorSysAlert = 'hsla(0, 72%, 51%, 1)';
	const colorSysBgAlert = 'hsla(0, 86%, 97%, 1)';

	// Warning
	const colorSysTextWarning = 'hsla(32, 81%, 29%, 1)';
	const colorSysWarning = 'hsla(45, 93%, 47%, 1)';
	const colorSysBgWarning = 'hsla(60, 77%, 95%, 1)';
	// Success
	const colorSysTextSuccess = 'hsla(142, 72%, 29%, 1)';
	const colorSysSuccess = 'hsla(142, 76%, 36%, 1)';
	const colorSysBgSuccess = 'hsla(138, 76%, 97%, 1)';
	// Info
	const colorSysTextInfo = 'hsla(226, 71%, 40%, 1)';
	const colorSysInfo = 'hsla(221, 83%, 53%, 1)';
	const colorSysBgInfo = 'hsla(216, 100%, 97%, 1)';
	// Off
	const colorSysTextOff = 'hsla(0, 0%, 97%, 1)';
	const colorSysOff = 'hsla(0, 0%, 81%, 1)';
	const colorSysBgOff = 'hsla(0, 0%, 32%, 1)';

	return {
		brandColor,
		colorPrimary,
		colorPrimarySoft: customColorVariables?.colorPrimarySoft || colorPrimarySoft,
		colorHead: customColorVariables?.colorHead || colorHead,
		colorText: customColorVariables?.colorText || colorText,
		colorMediumShadow: customColorVariables?.colorMediumShadow || colorMediumShadow,
		colorDarkShadow: customColorVariables?.colorDarkShadow || colorDarkShadow,
		colorLightShadow: customColorVariables?.colorLightShadow || colorLightShadow,
		colorBorder: customColorVariables?.colorBorder || colorBorder,
		colorCaption: customColorVariables?.colorCaption || colorCaption,
		colorNote: customColorVariables?.colorNote || colorNote,
		colorInactive: customColorVariables?.colorInactive || colorInactive,
		colorHeadOnDark: customColorVariables?.colorHeadOnDark || colorHeadOnDark,
		colorTextOnDark: customColorVariables?.colorTextOnDark || colorTextOnDark,
		colorBgBody: customColorVariables?.colorBgBody || colorBgBody,
		colorBgOverlay: customColorVariables?.colorBgOverlay || colorBgOverlay,
		colorBgSurface: customColorVariables?.colorBgSurface || colorBgSurface,
		colorSysTextDefault: customColorVariables?.colorSysTextDefault || colorSysTextDefault,
		colorSysDefault: customColorVariables?.colorSysDefault || colorSysDefault,
		colorSysBgDefault: customColorVariables?.colorSysBgDefault || colorSysBgDefault,
		colorSysTextAlert: customColorVariables?.colorSysTextAlert || colorSysTextAlert,
		colorSysAlert: customColorVariables?.colorSysAlert || colorSysAlert,
		colorSysBgAlert: customColorVariables?.colorSysBgAlert || colorSysBgAlert,
		colorSysTextWarning: customColorVariables?.colorSysTextWarning || colorSysTextWarning,
		colorSysWarning: customColorVariables?.colorSysWarning || colorSysWarning,
		colorSysBgWarning: customColorVariables?.colorSysBgWarning || colorSysBgWarning,
		colorSysTextSuccess: customColorVariables?.colorSysTextSuccess || colorSysTextSuccess,
		colorSysSuccess: customColorVariables?.colorSysSuccess || colorSysSuccess,
		colorSysBgSuccess: customColorVariables?.colorSysBgSuccess || colorSysBgSuccess,
		colorSysTextInfo: customColorVariables?.colorSysTextInfo || colorSysTextInfo,
		colorSysInfo: customColorVariables?.colorSysInfo || colorSysInfo,
		colorSysBgInfo: customColorVariables?.colorSysBgInfo || colorSysBgInfo,
		colorSysTextOff: customColorVariables?.colorSysTextOff || colorSysTextOff,
		colorSysOff: customColorVariables?.colorSysOff || colorSysOff,
		colorSysBgOff: customColorVariables?.colorSysBgOff || colorSysBgOff,
		colorLightShadowNoOpacity: customColorVariables?.colorLightShadowNoOpacity || colorLightShadowNoOpacity,
	};
};
