import { makeStyles } from '@material-ui/core';

export const useDropdownStyles = makeStyles(
	({
		typography: { smallViewPort },
		palette: {
			colorScheme: { colorVariables },
			borderRadius,
			boxShadow,
			sizes,
		},
	}) => ({
		root: {
			backgroundColor: colorVariables.colorPrimarySoft,
			borderRadius: borderRadius.commonRadius,
			transition: 'all 0.8s',
			minHeight: sizes.size_6,
			padding: `0 ${sizes.size_2}px`,
			outline: `${sizes.sizeQuarter}px solid transparent`,
			'&.Mui-focused': {
				transition: 'all 0.1s',
			},
			'&:hover': {
				transition: 'all 0.1s',
				borderColor: colorVariables.colorNote,
			},
			'&:focus-visible': {
				backgroundColor: colorVariables.colorPrimarySoft,
				outline: `${sizes.sizeQuarter}px solid ${colorVariables.colorPrimary}`,
				outlineOffset: 2,
				transition: 'outline 0s',
				borderRadius: borderRadius.commonRadius,
			},
			'&:focus:not(:focus-visible), &:active:not(:focus-visible)': {
				backgroundColor: colorVariables.colorPrimarySoft,
				boxShadow: `0px 0px 0px 1px ${colorVariables.colorPrimarySoft}`,
				borderRadius: borderRadius.commonRadius,
			},
			...smallViewPort.typeInput1,
			lineHeight: `${sizes.size_6}px`,
		},
		select: {
			paddingRight: `${sizes.size_6}px !important`,
		},
		paper: {
			borderRadius: borderRadius.commonRadius,
			boxShadow: boxShadow.angle.large,
		},
		list: {
			paddingTop: 0,
			paddingBottom: 0,
			maxHeight: '278px',
			overflowY: 'auto',
			'& li:hover': {
				background: colorVariables.colorPrimarySoft,
			},
			'& li.Mui-selected': {
				background: colorVariables.colorPrimarySoft,
			},
			'& li.Mui-selected:hover': {
				background: colorVariables.colorPrimarySoft,
			},
		},
		listMultiple: {
			paddingTop: 0,
			paddingBottom: 0,
			maxHeight: '278px',
			overflowY: 'auto',
			'& li:hover': {
				background: colorVariables.colorPrimarySoft,
			},
			'& li.Mui-selected': {
				background: 'none',
			},
			'& li.Mui-selected:hover': {
				background: colorVariables.colorPrimarySoft,
			},
		},
		iconWrapper: {
			position: 'absolute',
			right: '20px',
		},
		placeholderTextColor: {
			'&> div:first-child': {
				color: colorVariables.colorCaption,
			},
		},
		uncheckedOptionIcon: {
			position: 'absolute',
			right: sizes.size_2,
			fill: 'white',
		},
		checkedOptionIcon: {
			fill: 'white',
			position: 'absolute',
			right: 20,
		},
		circleIcon: {
			position: 'absolute',
			right: sizes.size_2,
		},
	}),
);
