import classNames from 'classnames';
import { format } from 'date-fns';
import { ElementType, FC, Fragment, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TimeSlotItem } from '../../../models/appointment.model';
import { Button, ButtonProps } from '../../../ui-kit/button/button.component';
import { Chip } from '../../../ui-kit/chip/chip.component';
import { DatePicker } from '../../../ui-kit/date-picker/date-picker.component';
import { Icon } from '../../../ui-kit/icon/icon.component';
import { CalendarUnavailableIcon } from '../../../ui-kit/icons/calendar-unavailable.icon';
import { ErrorIcon } from '../../../ui-kit/icons/error.icon';
import { Effect, Lazy } from '../../../utils/function.utils';
import { dateToFormattedDateString, getUTCDate } from '../../../utils/time.utils';
import { Nullable } from '../../../utils/types.utils';
import { AppointmentSchedulerTemplates, Dailyslots } from '../appointment-scheduling.model';
import { useAppointmentSchedulerStyles } from './appointment-scheduler.styles';
import { ErrorState } from './error-state/error-state.component';
import { LoadingSlots } from './loading-slots/loading-slots.component';

interface ErrorDataState {
	headerText?: string;
	text: string;
	icon: ElementType;
	altIconText: string;
	linkText: string;
	cb: Lazy<void>;
	testingLabel: string;
}
interface AppointmentSchedulerProps {
	daysWithSlots: Dailyslots[];
	isSearching: boolean;
	isError: boolean;
	availableCalendarDays: string[];
	canFetchMoreSlots: boolean;
	onSelectSlot: Effect<TimeSlotItem | null>;
	onSelectDay: Effect<string>;
	onSelectMonth: (date: Date) => Promise<any>;
	onChangeDoctor: Effect<string>;
	onSchedule: Effect<string>;
	onTryAgain: Lazy<void>;
	onOpenCalendar: Lazy<void>;

	// scheduler props
	doctorName?: string;
	selectedDay?: string; // goes to datepicker as value
	selectedSlot?: string;
	templates?: Nullable<AppointmentSchedulerTemplates>;
}

const getAvailableSlotsOfDay = (daysWithSlots: Dailyslots[], selectedDay?: string): TimeSlotItem[] => {
	const dayWithSlots = daysWithSlots.find((day) => day.date === selectedDay);
	return dayWithSlots ? dayWithSlots.slots : [];
};

export const AppointmentScheduler: FC<AppointmentSchedulerProps> = memo(
	({
		daysWithSlots,
		isSearching,
		isError,
		selectedDay,
		selectedSlot,
		availableCalendarDays,
		canFetchMoreSlots,
		onSelectSlot,
		onSelectDay,
		onSelectMonth,
		onSchedule,
		onOpenCalendar,
		onChangeDoctor,
		onTryAgain,
		templates,
	}) => {
		const {
			t,
			i18n: { language },
		} = useTranslation();

		const classes = useAppointmentSchedulerStyles();
		const [isDatePickerOpen, setisDatePickerOpenState] = useState(false);

		const datePickerRef = useRef<HTMLDivElement>(null);

		const handleCalendarDayChange = (date: Date) => {
			const selectedDate = dateToFormattedDateString(date);
			setisDatePickerOpenState(false);
			onSelectDay(selectedDate);
		};

		const isCalendarDisabled = isSearching || isError || daysWithSlots.length === 0;
		const datePickerLabel = isError || (!isSearching && daysWithSlots.length === 0) ? '' : t('date', 'Date');
		const selectedCalendarDate =
			selectedDay && !(isError || (!isSearching && daysWithSlots.length === 0)) ? getUTCDate(selectedDay) : null;

		const dailySlots = getAvailableSlotsOfDay(daysWithSlots, selectedDay);
		const numberOfAvailableSlots = `${dailySlots.length} ${t(
			'appointmentSchedulerSlotsAvailable',
			'slots available',
		)}`;
		const changeDoctorLabel =
			templates?.changeProvider == null
				? t('nextAvailableChangeDoctorButtonLabel', 'Change provider/clinic')
				: templates?.changeProvider;

		const renderSlots = (dailySlots: TimeSlotItem[]) => (
			<div className={classes.slotsContainer}>
				{dailySlots.map((dailySlot) => {
					const isSelected = selectedSlot === dailySlot.time;
					return (
						<Chip
							dataTestingLabel={`time-slots-button-${dailySlot.time}${isSelected ? '-selected' : ''}`}
							className={classes.timeSlot}
							key={dailySlot.time}
							label={dailySlot.displayTime}
							isSelected={isSelected}
							onClick={() => onSelectSlot(isSelected ? null : dailySlot)}
						/>
					);
				})}
			</div>
		);

		const getScheduleButtonLabel = () => {
			const selectedTimeSlotLabel = dailySlots.find((slot) => slot.time === selectedSlot)?.displayTime || '';
			const selectedDayLabel = selectedDay ? format(getUTCDate(new Date(selectedDay)), 'MMM d') : '';

			return t('appointmentSchedulerScheduleButtonLabel', 'Schedule for {{day}} at {{time}}', {
				day: selectedDayLabel,
				time: selectedTimeSlotLabel,
			});
		};

		const renderScheduleButton = () => {
			const buttonData = selectedSlot
				? {
						label: getScheduleButtonLabel(),
						color: 'primary',
						dataTestingLabel: 'schedule-appointment-widget-button',
					}
				: {
						label: t('appointmentSchedulerScheduleButtonLabelDefault', 'Select a time'),
						color: 'secondary',
						dataTestingLabel: 'unselected-schedule-appointment-widget-button',
					};

			return (
				<Button
					data-testing-label={buttonData.dataTestingLabel}
					color={buttonData.color as ButtonProps['color']}
					isFullWidth
					disabled={isDatePickerOpen || !selectedSlot}
					onClick={() => onSchedule(buttonData.label)}>
					{buttonData.label}
				</Button>
			);
		};

		const renderContent = (isSearching: boolean) => {
			const buttonsContainerClasses = classNames(
				classes.buttonContainer,
				selectedSlot && classes.buttonContainerSticky,
			);
			return (
				<Fragment>
					{isSearching ? (
						<LoadingSlots dataTestingLabel={'appointment-scheduler-searching-label-searching-slots'} />
					) : (
						<Fragment>
							<div className={classNames(classes.withSeparator, classes.paddingContainer)}>
								<span
									className={classes.numberOfSlots}
									data-testing-label={'appointment-scheduler-total-slots-number'}>
									{numberOfAvailableSlots}
								</span>
							</div>
							{dailySlots.length > 0 && renderSlots(dailySlots)}
							<div className={buttonsContainerClasses}>{renderScheduleButton()}</div>
						</Fragment>
					)}
				</Fragment>
			);
		};

		const renderErrorState = (errorStateData: ErrorDataState) => (
			<ErrorState
				header={errorStateData.headerText}
				text={errorStateData.text}
				icon={
					<Icon
						viewBox={'0 0 32 32'}
						icon={errorStateData.icon}
						size={'large'}
						iconType={'systemIconDefault'}
						alt={errorStateData.altIconText}
					/>
				}
				link={{
					text: errorStateData.linkText,
					action: errorStateData.cb,
				}}
				testingLabel={errorStateData.testingLabel}
			/>
		);

		const getErrorStateData = (
			isError: boolean,
			isSearching: boolean,
			isEmpty: boolean,
		): Nullable<ErrorDataState> => {
			if (isError) {
				return {
					headerText: t('appointmentSchedulingCantShowSlots', "Can't show slots"),
					text: t('appointmentSchedulingUnableToChangeSlots', 'Unable to load available slots.'),
					icon: ErrorIcon,
					altIconText: 'error icon',
					linkText: t('tryAgain', 'Try again'),
					cb: onTryAgain,
					testingLabel: 'next-available-error-message-container',
				};
			}
			if (!isSearching && isEmpty) {
				return {
					text: t('appointmnetSchedulingNoSlots', 'No slots available for this provider/clinic.'),
					icon: CalendarUnavailableIcon,
					altIconText: 'unavailable calendar icon',
					linkText: changeDoctorLabel,
					cb: () => onChangeDoctor(changeDoctorLabel),
					testingLabel: 'next-available-no-slots-container',
				};
			}

			return null;
		};

		const renderContentWithStates = (isError: boolean, isSearching: boolean, isEmpty: boolean) => {
			const errorData = getErrorStateData(isError, isSearching, isEmpty);

			const rendered = errorData ? renderErrorState(errorData) : renderContent(isSearching);
			return (
				<Fragment>
					<div className={classes.renderWrapper}>{rendered}</div>
					{(isError || (!isSearching && !isEmpty)) && (
						<Button
							data-testing-label={'appointment-scheduler-change-doctor-widget-button'}
							variant={'text'}
							size={'medium'}
							onClick={() => onChangeDoctor(changeDoctorLabel)}
							className={classes.linkButton}>
							{changeDoctorLabel}
						</Button>
					)}
				</Fragment>
			);
		};

		useEffect(() => {
			if (isDatePickerOpen) {
				onOpenCalendar();
			} else {
				datePickerRef.current?.focus();
			}
		}, [isDatePickerOpen]);

		return (
			<div className={classes.container} data-testing-label={'appointment-scheduler'}>
				<div className={classes.paddingContainer}>
					<DatePicker
						ref={datePickerRef}
						onStateChange={setisDatePickerOpenState}
						isDisabled={isCalendarDisabled}
						selectedDate={selectedCalendarDate}
						onDateChage={handleCalendarDayChange}
						onMonthChange={onSelectMonth}
						availableDays={availableCalendarDays}
						isNextMonthAvailable={canFetchMoreSlots}
						language={language}
						label={datePickerLabel}
						noAvailableDaysLabel={t('appointmentSchedulerNoSlots', 'No slots available this month')}
					/>
				</div>
				{renderContentWithStates(isError, isSearching, !dailySlots.length)}
			</div>
		);
	},
);
